import React, { useRef, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

// import required modules
import { Pagination, Navigation } from 'swiper/modules'
import { AnimateOnScroll } from 'animate-on-scroll-framer'

const DATA = [
  {
    name: 'Stealth Launch',
    phase: 'Phase 01',
    description: `Fair launch of $YIELD token. Initiate operations in stealth mode to refine strategy and
infrastructure.
`,
  },
  {
    name: 'Foundation and Branding',
    phase: 'Phase 02',
    description: `File all legal registrations necessary for the DAO. Establish brand, publish all essential
documentation, and unveil a new website.
`,
  },
  {
    name: 'First Acquisition of the DAO & Announcements',
    phase: 'Phase 03',
    description: `Exit stealth mode and publicly announce the acquisition of our first property. Introduce staking
pools and the development of our decentralized application (dApp).`,
  },
  {
    name: 'Property Acquisition & Marketing Initiatives',
    phase: 'Phase 04',
    description: `Complete the acquisition of the first property and begin monetization efforts. Launch an
extensive marketing campaign, including video reels, branded merchandise in apartments, and
video apartment tours.`,
  },
  {
    name: 'Rewards',
    phase: 'Phase 05',
    description: `To celebrate the acquisition of our first property we will reward early $YIELD holders. We have
been taking snapshots since the launch and will reward early buyers that held $YIELD until
staking is live.`,
  },
  {
    name: 'dAPP',
    phase: 'Phase 06',
    description: `dAPP is live with the YieldStone ecosystem and pools, including a commitment pool, a lending
pool, and $YIELD staking. Announce the introduction of a secondaries marketplace.
`,
  },
  {
    name: 'Profit Sharing',
    phase: 'Phase 07',
    description: `Begin the first round of quarterly profit distributions to REID shareholders and $YIELD stakers,
marking a significant milestone in revenue sharing.
`,
  },
  {
    name: 'Portfolio Growth & Leverage',
    phase: 'Phase 08',
    description: `Expand the property portfolio through additional acquisitions. Leverage relationships with
banking institutions to enhance liquidity with off-chain lending.`,
  },
]

export default function Roadmap() {
  return (
    <div
      className='md:px-10 px-5'
      id='roadmap'
    >
      <div className='bg-white md:py-20 py-10'>
        <AnimateOnScroll animation='flipIn'>
          <p className='text-[#545454] text-xl  font-medium text-center'>
            Roadmap
          </p>
        </AnimateOnScroll>
        <AnimateOnScroll animation='flipIn'>
          <h3 className='font-medium text-[40px] text-[#0E0339] text-center'>
            What we Plan Ahead of Us
          </h3>
        </AnimateOnScroll>
        <div className='container mx-auto'>
          <Swiper
            navigation={true}
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              480: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            modules={[Navigation]}
            className='mySwiper !relative'
          >
            {DATA.map((item, index) => (
              <SwiperSlide key={index}>
                <div className='border border-[#D6C8F3] rounded-[30px] p-10 bg-white !h-[350px]'>
                  <h3
                    className='font-medium text-start text-xl text-[#0E0339] 
            '
                  >
                    {item.name}
                  </h3>
                  <p className='bg-[#B7B7B74D] text-[#5D5F61] px-4 py-1 rounded-full w-[130px] my-4'>
                    {item.phase}
                  </p>
                  <p className='text-base text-start text-[#838383] font-extralight'>
                    {item.description}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}
