import { AnimateOnScroll } from 'animate-on-scroll-framer'
import React from 'react'

export default function Modal() {
  return (
    <div
      className='container mx-auto md:px-10 px-5 modal -mt-32 md:mt-10 modal '
      id='about'
    >
      <div className='grid lg:grid-cols-2 grid-cols-1'>
        <div className='space-y-5'>
          <AnimateOnScroll animation='flipIn'>
            <h3 className='animate__animated animate__backInDown font-semibold text-[40px] text-[#0E0339] animate__animated  animate__zoomInDown'>
              Our Model
            </h3>
          </AnimateOnScroll>

          <p className='text-[#838383] font-light animate__animated  animate__zoomInDown'>
            YieldStone harnesses blockchain technology to offer access to
            sophisticated real estate investment vehicles. We operate as a DAO,
            blending the best qualities of two lucrative models: Real Estate
            Investment Trusts (REITs) and Private Equity Real Estate (PERE)
            funds, bringing them on-chain for the first time. <br />
            <br />
            By strategically acquiring a globally diversified portfolio of
            properties through our DAO, we enhance the predictability of
            returns. Our international presence allows us to capitalize on
            regional strengths—some areas offer higher rental incomes relative
            to property values, while others provide better leverage options.
            This combination enhances the overall performance of the DAO.
            <br />
            <br />
            Our holders can earn steady yields derived from rental income and
            property value appreciation. With our innovative hybrid model, we
            harness the liquidity and regular income of REITs along with the
            strategic growth potential of PERE. This fusion with blockchain
            technology creates accessible, high-yield investment opportunities
            for a broader audience.
            <br />
            <br />
            Our platform empowers anyone to invest in global high-yield real
            estate markets with transparency and trust. With YieldStone,
            experience the freedom to invest in real estate on-chain.
          </p>
          <AnimateOnScroll animation='flipIn'>
            <div className='md:mt-14 mt-5'>
              <a
                href='#token'
                className='animate__animated  animate__zoomInDown   shadow-md bg-[#0E0339] text-white px-10 py-3 rounded-full   hover:bg-white hover:text-[#0E0339] hover:border hover:border-[#0E0339] '
              >
                Tokenomics
              </a>
            </div>
          </AnimateOnScroll>
        </div>
        <div>
          <div className='relative animate__animated animate__backInDown '>
            <AnimateOnScroll animation='flipIn'>
              <img
                src='/images/modal.png'
                alt='modal'
              />
            </AnimateOnScroll>
            <img
              src='/images/clock.png'
              className='absolute md:w-8 md:h-8 w-4 h-4 rotate top-[36%] md:left-[23%] left-[23.3%]'
              width={33}
              height={33}
            />
            <img
              src='/images/car.png'
              className='absolute md:w-8 md:h-8 w-4 h-4 bottomTop top-[31%] md:left-[33.5%] left-[33.6%]'
              width={33}
              height={33}
            />
            <img
              src='/images/sms.png'
              className='absolute md:w-8 md:h-8 w-4 h-4 bottomTop top-[17%] left-[44.5%]'
              width={33}
              height={33}
            />
            <img
              src='/images/start.png'
              className='absolute md:w-6 md:h-6 w-4 h-4 rotate top-[27%] md:left-[49.3%] left-[49%]'
              width={30}
              height={30}
            />
            <div className='absolute top-[12%] left-[60.5%]'>
              <iframe
                src='https://lottie.host/embed/ca6e94fd-da7c-41da-bb8f-caee9438764c/Ip502pyfJs.json'
                className='md:w-8 md:h-8 w-4 h-4 '
              ></iframe>
            </div>
            <img
              src='/images/return.png'
              className='absolute md:w-6 md:h-6 w-4 h-4 rotate bottom-[37%] md:left-[45.7%] left-[45.4%]'
              width={30}
              height={30}
            />
            <img
              src='/images/user.png'
              className='absolute md:w-8 md:h-8 w-4 h-4 bottomTop top-[44%] left-[55%]'
              width={30}
              height={30}
            />
            <img
              src='/images/fire.png'
              className='absolute md:w-8 md:h-8 w-4 h-4 rotate top-[22%] left-[62.7%]'
              width={33}
              height={33}
            />
            <img
              src='/images/signal.png'
              className='absolute md:w-8 md:h-8 w-4 h-4 bottomTop top-[30%] md:right-[22.5%] right-[23%]'
              width={33}
              height={33}
            />
            <img
              src='/images/eye.png'
              className='absolute md:w-8 md:h-8 w-4 h-4 bottomTop top-[14%] md:right-[14%] right-[14.6%]'
              width={33}
              height={33}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
