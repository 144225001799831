import React from 'react'
import Hero from '../components/Hero'
import Slider from '../components/slider'
import Modal from '../components/modal'
import JoinUs from '../components/joinus'
import Services from '../components/services'
import Token from '../components/token'
import Roadmap from '../components/Roadmap'

export default function Home() {
  return (
    <>
      <Hero />
      <Slider />
      <Modal />
      <JoinUs />
      <Services />
      <Token />
      <Roadmap />
    </>
  )
}
