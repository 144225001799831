import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from './mobileMenu'
import { FaBarsStaggered } from 'react-icons/fa6'

// import { MdOutlineSegment } from 'react-icons/md'

const NAV_LINKS = [
  {
    name: 'About',
    linkPath: '#about',
  },
  {
    name: 'Features',
    linkPath: '#features',
  },
  {
    name: 'Tokenomics',
    linkPath: '#token',
  },
  {
    name: 'Roadmap',
    linkPath: '#roadmap',
  },
]

export default function Navbar() {
  const [menuShow, setMenuShow] = useState(false)

  const HandleClick = () => {
    setMenuShow(true)
  }

  return (
    <div className='relative'>
      <nav className='md:px-10 px-5 py-6 bg-[#070324] flex items-center justify-between '>
        <div>
          <Link to='/'>
            <img
              src='/images/logo.png'
              alt='logo'
              className='w-full md:h-10 h-8 object-contain'
            />
          </Link>
        </div>
        <div className='lg:block hidden'>
          <ul className='flex items-center gap-10'>
            {NAV_LINKS.map((item, index) => (
              <li
                key={index}
                className=' text-white'
              >
                <a href={item.linkPath}>{item.name}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className='md:block hidden'>
          <button className='navButton !capitalize'>Dapp-Coming Soon</button>
        </div>
        <div className='md:hidden block relative text-white'>
          <button onClick={HandleClick}>
            <FaBarsStaggered />
          </button>
        </div>
      </nav>
      <div className='animation'>
        {menuShow && <MobileMenu setMenuShow={setMenuShow} />}
      </div>
    </div>
  )
}
