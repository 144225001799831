import React from 'react'
import { Link } from 'react-router-dom'
import { IoMdClose } from 'react-icons/io'

const NAV_LINKS = [
  {
    name: 'About',
    linkPath: '#about',
  },
  {
    name: 'Features',
    linkPath: '#features',
  },
  {
    name: 'Tokenomics',
    linkPath: '#token',
  },
  {
    name: 'Roadmap',
    linkPath: '#roadmap',
  },
]
export default function MobileMenu({ setMenuShow }) {
  const HandleClose = () => {
    setMenuShow(false)
  }

  return (
    <div className='fixed h-[100vh] z-50 right-0 top-0 bg-black w-[300px] text-white py-10 px-5 animation'>
      <span
        className='absolute top-7 right-7'
        onClick={HandleClose}
      >
        <IoMdClose />
      </span>
      <div className='pl-10'>
        <ul className='flex flex-col gap-6  mt-10'>
          {NAV_LINKS.map((item, index) => (
            <li
              key={index}
              className='font-semibold text-white'
            >
              <a
                onClick={HandleClose}
                href={item.linkPath}
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
        <div className='lg:hidden block mt-6'>
          <button className='navButton !capitalize'>Dapp-Coming Soon</button>
        </div>
      </div>
    </div>
  )
}
