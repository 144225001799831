import { AnimateOnScroll } from 'animate-on-scroll-framer'
import React from 'react'
import { Link } from 'react-router-dom'

export default function JoinUs() {
  return (
    <div className='bg-[#27D6901A] md:mt-20 mt-10 py-10'>
      <div className='container mx-auto md:px-10 px-5'>
        <div className='grid lg:grid-cols-2 grid-cols-1 gap-10'>
          <div>
            <h3 className='font-medium text-[40px] text-[#0E0339] mb-5'>
              More about YieldStone
            </h3>
            <div className='relative'>
              <img
                src='/images/join-us.png'
                alt='join img'
              />
              <a
                href='https://t.me/Yieldstone'
                target='_blank'
                className='flex flex-col  text-[#0E0339] md:text-3xl xl:text-3xl lg:text-xl font-medium absolute right-0  xl:bottom-10 lg:bottom-5 md:bottom-10 bottom-6'
              >
                Join our
                <p className='flex items-center gap-2'>
                  Telegram Today
                  <svg
                    width='21'
                    height='22'
                    viewBox='0 0 21 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M0.90948 18.9701C0.340663 19.5723 0.367787 20.5217 0.970065 21.0905C1.57234 21.6593 2.5217 21.6322 3.09052 21.0299L0.90948 18.9701ZM20.4994 1.95716C20.4757 1.12907 19.7852 0.476952 18.9572 0.500612L5.46267 0.886168C4.63458 0.909828 3.98246 1.60031 4.00612 2.4284C4.02978 3.25649 4.72026 3.90861 5.54835 3.88495L17.5435 3.54223L17.8862 15.5373C17.9098 16.3654 18.6003 17.0175 19.4284 16.9939C20.2565 16.9702 20.9086 16.2797 20.8849 15.4517L20.4994 1.95716ZM3.09052 21.0299L20.0905 3.02993L17.9095 0.970064L0.90948 18.9701L3.09052 21.0299Z'
                      fill='#0E0339'
                    />
                  </svg>
                </p>
              </a>
            </div>
          </div>
          <div className=' md:mt-20 mt-0'>
            <div className=' flex gap-2 items-start '>
              <div>
                <img
                  src='/images/doucment.png'
                  alt='doucment'
                  width={80}
                  height={80}
                />
              </div>
              <AnimateOnScroll animation='flipIn'>
                <div>
                  <h3 className='font-medium text-xl text-[#0E0339]  mb-2'>
                    Competitive Landscape
                  </h3>
                  <p className='text-[#545454] font-extralight'>
                    YieldStone operates uniquely as a DAO, acquiring a diverse
                    global property portfolio. It blends REITs & PERE funds,
                    bringing it onto the blockchain for enhanced strength. This
                    innovative approach combines REIT liquidity with PERE growth
                    potential, offering accessible high-value opportunities.
                  </p>
                </div>
              </AnimateOnScroll>
            </div>
            <hr className='my-10  ' />
            <div className=' flex gap-2 items-start relative'>
              <div>
                <img
                  src='/images/team.png'
                  alt='doucment'
                  width={80}
                  height={80}
                />
              </div>
              <AnimateOnScroll animation='flipIn'>
                <div>
                  <h3 className='font-medium text-xl text-[#0E0339] mb-2'>
                    Team
                  </h3>

                  <p className='text-[#545454] font-extralight'>
                    YieldStone's success rests on its expert management team,
                    with a rich blend of real estate & financial experience.
                    Veterans from prestigious institutions like Goldman Sachs
                    lead the way, skilled in managing large-scale real estate
                    portfolios & structured financial products. Their innovation
                    includes blockchain adoption for transparency & security,
                    positioning YieldStone as a leader. With a commitment to
                    ethical governance, operations prioritize investor
                    interests.
                  </p>
                </div>
              </AnimateOnScroll>
              <img
                src='/images/Ellipse.png'
                alt='Ellipse'
                className='absolute '
              />
            </div>
            <hr className='my-10  ' />
            <div className=' flex gap-2 items-start '>
              <div>
                <img
                  src='/images/Compliance.png'
                  alt='doucment'
                  width={80}
                  height={80}
                />
              </div>
              <AnimateOnScroll animation='flipIn'>
                <div>
                  <h3 className='font-medium text-xl text-[#0E0339] mb-2'>
                    Compliance and Ethics
                  </h3>
                  <p className='text-[#545454] font-extralight'>
                    YieldStone prioritizes compliance & ethics in blockchain and
                    real estate investments, integrating robust regulatory
                    frameworks across jurisdictions. Leveraging blockchain tech
                    enhances transaction transparency & security, fostering
                    trust. Proactively monitoring & adapting to regulations
                    ensures seamless integration with innovative blockchain
                    applications. Collaborating with lending institutions
                    reinforces operational reliability & builds trust with
                    investors & partners.
                  </p>
                </div>
              </AnimateOnScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
