import React from 'react'
import Marquee from 'react-fast-marquee'

export default function Slider() {
  return (
    <div className='  slider'>
      <div className='relative z-50  -translate-y-10 '>
        <div className='py-5 w-full bg-[#868686]'></div>
        <div className='py-5 w-full bg-[#6cdda3]'></div>

        <Marquee className='bg-[#070324] py-5 xxl:py-10'>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map(
            (idx) => (
              <img
                key={idx}
                src='/images/logo.png'
                alt='logo'
                width={210}
                height={37}
                className='mr-16'
              />
            )
          )}
        </Marquee>
      </div>
    </div>
  )
}
