import { AnimateOnScroll } from 'animate-on-scroll-framer'
import React from 'react'

const TOKEN_DATA = [
  {
    imagePath: '/images/token-images (1).png',
    name: 'REID I Subscribers',
    description: `Subscribers to YieldStone REID I lock $USDC in our Commitment Pool while it is open and receive REID I shares based on the capital committed. Once the pool is closed, no new REID I shares will be issued as it is a closed-end investment vehicle. Shareholders will receive distributions in $USDC from the profits generated by the REID. These REID shares, distinct from $YIELD tokens, will be tradable in our secondary marketplace.
`,
    discription2: `The committed capital is locked for a 5-year investment period. During this time, we utilize the liquidity, combined with leverage, to acquire and monetize real estate assets. Capital is returned to shareholders at the end of the investment period and earlier through distributions. Fees follow the standard “2&20” model: the DAO will receive a 2% management fee on committed capital and a 20% performance fee on profit.
`,
  },
  {
    imagePath: '/images/token-images (2).png',
    name: 'Liquidity Providers (LPs)',
    description: `LPs provide liquidity to the lending pools by supplying 
stablecoins such as USDC. The REID is able to borrow capital from the lending pool and pay 
interest to LPs on the capital borrowed. LPs earn returns based on competitive APY rates, 
supporting the platform’s liquidity and enabling efficient financial operations.`,
  },
  {
    imagePath: '/images/token-images (3).png',
    name: '$YIELD Token Holders',
    description: `$YIELD holders benefit from sharing the revenue generated by the 
DAO, which collects a 20% performance fee on the REID profits. Additionally, holders play a 
crucial role in managing and decision-making within the DAO, as the $YIELD token 
represents governance rights, with holders being able to vote on new properties to be 
acquired by the REID`,
  },
]

export default function Token() {
  return (
    <div
      className='bg-[#040325] text-white md:mt-20 mt-10 md:pt-44 pb-0 pt-10 relative right-0'
      id='token'
    >
      <img
        src='/images/Ellipse.png'
        alt='Ellipse'
        className='absolute -z-40 top-0'
      />
      <div className='container mx-auto md:px-10 px-5 relative'>
        <AnimateOnScroll animation='flipIn'>
          <p className='text-[#969696] text-xl  font-medium text-center'>
            Tokenomics
          </p>
        </AnimateOnScroll>
        <AnimateOnScroll animation='flipIn'>
          <h3 className='font-medium md:text-[40px] text-2xl text-white text-center '>
            YieldStone Ecosystem
          </h3>
        </AnimateOnScroll>
        <AnimateOnScroll animation='flipIn'>
          <p className='text-[#8D8D8D] text-lg text-center my-5'>
            YieldStone introduces a unique, structured high-yield investment
            vehicle onto the blockchain. With our innovative approach and model,
            we are launching the first-of-its-kind Real Estate Investment DAO:
            YieldStone REID I. YieldStone REID I is structured as a
            Decentralized Autonomous Organization (DAO), supported by an
            "off-chain" investment entity responsible for holding real estate
            assets. This organizational structure allows the DAO to acquire
            properties globally, aiming to yield substantial returns for
            investors through effective asset management and operational
            strategies. The YieldStone ecosystem is designed to engage three
            distinct market participants within a sustainably
            crafted business model:
          </p>
        </AnimateOnScroll>

        <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 mt-10'>
          {TOKEN_DATA.map((item, indx) => (
            <div
              className='bg-[#FFFFFF1A] rounded-3xl p-5 h-full '
              key={indx}
            >
              <AnimateOnScroll animation='flipIn'>
                <img
                  src={item.imagePath}
                  alt={item.name}
                  width={80}
                  height={80}
                />
              </AnimateOnScroll>
              <AnimateOnScroll animation='flipIn'>
                <h3 className='font-medium text-xl text-white my-5'>
                  {item.name}
                </h3>
              </AnimateOnScroll>
              <AnimateOnScroll animation='flipIn'>
                <p className='text-[#ADADAD] font-light text-sm'>
                  {item.description}
                  <br />
                  <br />
                  {item.discription2}
                </p>
              </AnimateOnScroll>
            </div>
          ))}
        </div>
        <AnimateOnScroll animation='flipIn'>
          <p className='text-[#8D8D8D] text-center text-lg md:mt-10 mt-5'>
            All three participants are essential for maintaining the flow of
            capital within the YieldStone ecosystem, which is why we created a
            system that rewards liquidity and risk accordingly. We have
            developed an innovative real estate investment product as well as a
            structured financial model that is economically sustainable. The DAO
            has been established with a profitable business model that benefits
            all parties. Besides collecting performance fees, the DAO also
            receives income from management fees (2% of committed capital) and
            buy & sell taxes (5%) to finance its operations. The treasury of the
            DAO is focused on providing capital for operations, legal, and
            marketing activities. YieldStone is a revolutionary product that
            combines high-yield investment vehicles, real estate investment, and
            decentralized finance. If you would like to learn more and get into
            the details of our model,{' '}
            <a href=''>we invite you to read our whitepaper.</a>
          </p>
        </AnimateOnScroll>
      </div>
      <div className='md:mt-20 mt-10'>
        <img
          src='/images/token_main_img.png'
          className='w-full lg:h-[810px] object-contain'
        />
      </div>
    </div>
  )
}
