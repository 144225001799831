import gsap from 'gsap'
import React, { useEffect } from 'react'
import { useGSAP } from '@gsap/react'
import Slider from './slider'

import 'animate.css'
import { Link } from 'react-router-dom'

export default function Hero() {
  // useGSAP(() => {
  //   var tl = gsap.timeline()

  //   tl.from('h1', {
  //     x: 100,
  //     duration: 0.5,
  //     delay: 0.3,
  //     opacity: 0,
  //   })
  //   tl.from('p', {
  //     x: 100,
  //     duration: 0.5,
  //     delay: 0.3,
  //     opacity: 0,
  //   })
  //   tl.from('.button_main button', {
  //     x: 100,
  //     duration: 0.5,
  //     delay: 0.3,
  //     opacity: 0,
  //   })

  //   gsap.from('img', {
  //     y: -100,
  //     duration: 0.7,
  //     delay: 0.7,
  //     opacity: 0,
  //     transition: 0.5,
  //   })
  // }, [])

  return (
    <div className='bg-[#070324] text-white md:px-10 px-5   '>
      <div className=' container mx-auto md:pt-24 pt-12 pb-36'>
        <div className='grid lg:grid-cols-12 md:gap-10 gap-20 grid-cols-1 justify-center items-center'>
          <div className='lg:col-span-5 col-span-full space-y-8 md:pl-5 '>
            <img
              src='/images/main-heaidng.png'
              alt='main heading '
              className='animate__animated  animate__zoomInDown'
            />

            <p className='animate__animated  animate__zoomInDown'>
              YieldStone provides blockchain-enabled access to sophisticated
              real estate investment vehicles, allowing a broader audience to
              participate in high-yield opportunities. Discover a unique
              platform that brings exclusive, structured, highly profitable
              investments on-chain for the first time.
            </p>
            <div className=' animate__animated animate__zoomInDown '>
              <a
                href='https://yieldstone.gitbook.io/yieldstone-whitepaper/'
                className=' bg-white text-black px-10 py-3 rounded-full '
              >
                Whitepaper
              </a>
            </div>
          </div>
          <div className='relative lg:col-span-7 col-span-full animate__animated animate__backInDown'>
            <img
              src='/images/hero.png'
              alt='hero'
            />

            <img
              src='/images/signal.png'
              className='absolute md:w-10 md:h-10 w-4 h-4 object-contain rotate top-[25%] left-[18%]  '
              width={40}
              height={40}
            />
            <img
              src='/images/headphone.png'
              className='absolute md:w-10 md:h-10 w-4 h-4 object-contain bottomTop top-[19%] left-[32.5%]'
              width={33}
              height={33}
            />
            <img
              src='/images/location.png'
              className='absolute md:w-10 md:h-10 w-4 h-4 object-contain bottomTop top-[6%] left-[47%]'
              width={24}
              height={24}
            />
            <img
              src='/images/setting.png'
              className='absolute md:w-10 md:h-10 w-4 h-4 object-contain rotate top-[20%] right-[28%]'
              width={24}
              height={24}
            />
            <div className='absolute  bottom-[38%] md:right-[33%] right-[34%]'>
              <iframe
                src='https://lottie.host/embed/f58e3b74-40b8-4d87-94c2-9ece3f7b635c/emyHv0mTAI.json'
                className='md:w-20 md:h-20 w-8 h-8 object-contain'
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
