import { AnimateOnScroll } from 'animate-on-scroll-framer'
import React from 'react'

const SERVICES_DATA = [
  {
    imagePath: '/images/services (1).png',
    name: 'Accesible Real Estate Investment',
    description: `Invest in high-value real estate with less capital compared to traditional methods`,
  },
  {
    imagePath: '/images/services (4).png',
    name: 'Passive Income Potential',
    description: `Benefit from a steady annual yield via staking, with the possibility of gaining from additional property earnings
`,
  },
  {
    imagePath: '/images/services (3).png',
    name: 'Enhanced Liquidity',
    description: `Experience improved liquidity and transparency when compared to traditional real estate investment avenues
`,
  },
  {
    imagePath: '/images/services (2).png',
    name: 'Effortless Management',
    description: `Enjoy the benefits of real estate investment without the need to manage, operate, or finance the properties directly.`,
  },
]

export default function Services() {
  return (
    <div
      className='bg-white md:mt-20 mt-10 py-10'
      id='features'
    >
      <div className='container mx-auto md:px-10 px-5'>
        <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-10'>
          {SERVICES_DATA.map((item, idx) => (
            <AnimateOnScroll
              animation='flipIn'
              key={idx}
            >
              <div className=' flex flex-col gap-6 justify-center items-center'>
                <img
                  src={item.imagePath}
                  alt={item.name}
                  width={90}
                  height={90}
                  className='animate__animated animate__backInDown'
                />

                <AnimateOnScroll animation='flipIn'>
                  <h3 className='capitalize font-medium text-2xl text-[#0E0339] text-center animate__animated animate__backInDown'>
                    {item.name}
                  </h3>
                </AnimateOnScroll>
                <AnimateOnScroll animation='flipIn'>
                  <p className='text-[#545454] font-extralight text-center'>
                    {item.description}
                  </p>
                </AnimateOnScroll>
              </div>
            </AnimateOnScroll>
          ))}
        </div>
      </div>
    </div>
  )
}
